@tailwind base;
@tailwind components;
@tailwind utilities;


.text-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Batasi jumlah baris */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}